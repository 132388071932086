import React from 'react'
import CombinedCategories from "../components/CombinedCategories";
function Category() {
    return (
        <div>
            <CombinedCategories />
        </div>
    )
}

export default Category;