import React from 'react';
import DA2I from '../assets/images/Parcours/da2i.png';
import MA from '../assets/images/Parcours/ma.png';
import RPM from '../assets/images/Parcours/RPM.png';

function CardMention() {
    return (
        <div className="flex justify-center space-x-32 -mt-32">
            <div
                className="relative bg-blue-300 rounded-[40px] w-64 h-72 before:content-[''] before:absolute before:top-[-15px] before:right-[-15px] before:w-full before:h-full before:border-[1px] before:border-blue-300 before:rounded-[50px] before:box-border before:bg-transparent before:z-[1]">
                <div className="absolute bottom-0 z-[2]">
                    <img
                        src={MA}
                        className="h-[350px] w-[290px] object-cover"
                        alt="AES"
                    />
                </div>
            </div>
            <div
                className="relative bg-blue-300 rounded-[40px] w-64 h-72 before:content-[''] before:absolute before:top-[-15px] before:right-[-15px] before:w-full before:h-full before:border-[1px] before:border-blue-300 before:rounded-[50px] before:box-border before:bg-transparent before:z-[1]">
                <div className="absolute bottom-0 z-[2]">
                    <img
                        src={DA2I}
                        className="h-[375px] w-[250px] object-cover"
                        alt="DA2I"
                    />
                </div>
            </div>
            <div
                className="relative bg-blue-300 rounded-[40px] w-64 h-72 before:content-[''] before:absolute before:top-[-15px] before:right-[-15px] before:w-full before:h-full before:border-[1px] before:border-blue-300 before:rounded-[50px] before:box-border before:bg-transparent before:z-[1]">
                <div className="absolute bottom-0 z-[2]">
                    <img
                        src={RPM}
                        className="h-[350px] w-[325px] object-cover"
                        alt="RPM"
                    />
                </div>
            </div>
        </div>
    );
}

export default CardMention;
