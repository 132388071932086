import React from 'react'
import BookHeader from "../components/views_title/BookHeader";
import Book from "../components/Book";

function Livre() {
    return (
        <div>
            <BookHeader />
            <Book />
        </div>
    )
}

export default Livre
