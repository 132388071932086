import React, { useEffect, useState } from 'react';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAllVideos, fetchFile } from "../apiConfig/services";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';

const CardVideo = () => {
    const [videos, setVideos] = useState([]);
    const [covers, setCovers] = useState({});
    const [loadingCovers, setLoadingCovers] = useState({});
    const [selectedType, setSelectedType] = useState('All');
    const [selectedKeywords, setSelectedKeywords] = useState([]);
    const [loading, setLoading] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const videosPerPage = 6;
    const location = useLocation();
    const navigate = useNavigate();
    const isHomePage = location.pathname === '/home' || location.pathname === '/';
    const isVideosPage = location.pathname === '/videos';

    // Filter videos by type and keywords
    const filteredVideos = videos.filter(video =>
        (selectedType === 'All' || video.typeFiche === selectedType) &&
        (selectedKeywords.length === 0 || selectedKeywords.some(keyword => video.nom_dom.includes(keyword)))
    );

    const displayedVideos = isHomePage ? filteredVideos.slice(0, 6) : filteredVideos;

    const indexOfLastVideo = currentPage * videosPerPage;
    const indexOfFirstVideo = indexOfLastVideo - videosPerPage;
    const currentVideos = isVideosPage ? displayedVideos.slice(indexOfFirstVideo, indexOfLastVideo) : displayedVideos;

    const totalPages = Math.ceil(displayedVideos.length / videosPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleVoirPlusClick = () => {
        navigate('/videos');
    };

    const handleKeywordChange = (keyword) => {
        setSelectedKeywords(prevKeywords =>
            prevKeywords.includes(keyword)
                ? prevKeywords.filter(kw => kw !== keyword)
                : [...prevKeywords, keyword]
        );
    };

    const handleClearFilters = () => {
        setSelectedKeywords([]);
        setSelectedType('All');
    };

    const handleVideoClick = (videoId) => {
        navigate(`/videos/${videoId}`);
    };

    useEffect(() => {
        const fetchVideos = async () => {
            try {
                const data = await getAllVideos();
                setVideos(data);
                setLoading(false);

                const initialLoadingCovers = data.reduce((acc, video) => {
                    acc[video.id_sup] = true;
                    return acc;
                }, {});
                setLoadingCovers(initialLoadingCovers);

                const coverPromises = data.map(async (video) => {
                    if (video.couverture_fiche) {
                        const coverUrl = await fetchFile(video.couverture_fiche, "couverture");
                        return { id_sup: video.id_sup, coverUrl };
                    }
                    return { id_sup: video.id_sup, coverUrl: null };
                });

                const coversData = await Promise.all(coverPromises);
                const coversDict = coversData.reduce((acc, { id_sup, coverUrl }) => {
                    acc[id_sup] = coverUrl;
                    return acc;
                }, {});

                setCovers(coversDict);
            } catch (error) {
                console.error('Erreur lors de la récupération des vidéos', error);
                setLoading(false);
            }
        };

        fetchVideos();
    }, []);

    const handleImageLoaded = (videoId) => {
        setLoadingCovers(prevLoadingCovers => ({
            ...prevLoadingCovers,
            [videoId]: false
        }));
    };

    const videoTypes = [...new Set(videos.map(video => video.typeFiche))];

    if (loading) {
        return <div className="text-center my-10"></div>;
    }

    if (videos.length === 0) {
        return <div className="text-center my-10">Aucune vidéo disponible.</div>;
    }

    return (
        <div className="container mx-auto flex flex-wrap mt-5">
            {/* Show filters only on the '/videos' page */}
            {isVideosPage && (
                <div className="w-full md:w-1/4 mb-8 md:mb-2 md:pr-8">
                    <div className="bg-white rounded-2xl p-6 shadow-sm border border-gray-100">
                        <div className="mb-8">
                            <h3 className="text-lg font-semibold mb-4 text-gray-800">Type de fiche</h3>
                            <div className="flex flex-col gap-2">
                                <button
                                    onClick={() => setSelectedType('All')}
                                    className={`px-4 py-2.5 rounded-xl text-sm font-medium transition-all duration-200
                                        ${selectedType === 'All'
                                            ? 'bg-primary text-white shadow-sm shadow-primary/30'
                                            : 'bg-gray-50 hover:bg-gray-100 text-gray-700'}`}
                                >
                                    Tous les domaines
                                </button>
                                {videoTypes.map(type => (
                                    <button
                                        key={type}
                                        onClick={() => setSelectedType(type)}
                                        className={`px-4 py-2.5 rounded-xl text-sm font-medium transition-all duration-200
                                            ${selectedType === type
                                                ? 'bg-primary text-white shadow-sm shadow-primary/30'
                                                : 'bg-gray-50 hover:bg-gray-100 text-gray-700'}`}
                                    >
                                        {type}
                                    </button>
                                ))}
                            </div>
                        </div>

                        <div className="space-y-4">
                            <h3 className="text-lg font-semibold text-gray-800">Domaines</h3>

                            {selectedKeywords.length > 0 && (
                                <div className="flex flex-wrap gap-2 mb-4">
                                    {selectedKeywords.map(keyword => (
                                        <span
                                            key={keyword}
                                            className="inline-flex items-center bg-primary/10 text-primary px-3 py-1 rounded-lg text-sm"
                                        >
                                            {keyword}
                                            <button
                                                onClick={() => handleKeywordChange(keyword)}
                                                className="ml-2 hover:text-primary/70"
                                            >
                                                <ClearRoundedIcon className="w-4 h-4" />
                                            </button>
                                        </span>
                                    ))}
                                </div>
                            )}

                            <div className="space-y-2 max-h-64 overflow-y-auto pr-2">
                                {Array.from(new Set(videos.map(video => video.nom_dom))).map(domain => (
                                    <label
                                        key={domain}
                                        className="flex items-center gap-3 p-2 rounded-lg hover:bg-gray-50 transition-colors cursor-pointer group"
                                    >
                                        <div className="relative flex items-center">
                                            <input
                                                type="checkbox"
                                                value={domain}
                                                checked={selectedKeywords.includes(domain)}
                                                onChange={() => handleKeywordChange(domain)}
                                                className="w-4 h-4 rounded border-gray-300 text-primary focus:ring-primary/30
                                                         transition-all duration-200 cursor-pointer"
                                            />
                                        </div>
                                        <span className="text-sm text-gray-700 group-hover:text-gray-900">
                                            {domain}
                                        </span>
                                    </label>
                                ))}
                            </div>

                            {(selectedKeywords.length > 0 || selectedType !== 'All') && (
                                <button
                                    onClick={handleClearFilters}
                                    className="w-full mt-6 px-4 py-2.5 rounded-xl text-sm font-medium
                                             bg-red-50 text-red-600 hover:bg-red-100
                                             transition-all duration-200 flex items-center justify-center gap-2"
                                >
                                    <ClearRoundedIcon className="w-4 h-4" />
                                    Réinitialiser les filtres
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )}

            <div className={`w-full ${isVideosPage ? 'md:w-3/4' : 'md:w-full'} flex flex-col`}>
                <div className={`grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3`}>
                    {currentVideos.map(video => (
                        <div
                            key={video.id_sup}
                            className="bg-blue-50 rounded-xl overflow-hidden shadow-sm transition-all duration-200
                                     hover:shadow-md hover:-translate-y-1 cursor-pointer"
                            onClick={() => handleVideoClick(video.id_sup)}
                        >
                            <div className="relative h-56 flex justify-center items-center">
                                {loadingCovers[video.id_sup] && (
                                    <div className="spinner border-t-2 border-primary border-solid rounded-full h-12 w-12 animate-spin"></div>
                                )}
                                <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                                    <PlayArrowRoundedIcon style={{ fontSize: 150 }} className="text-blue-300" />
                                </div>
                                <img
                                    src={covers[video.id_sup] || 'fallback-image-url.jpg'}
                                    alt={video.nom_sup}
                                    className={`object-cover w-full h-full ${loadingCovers[video.id_sup] ? 'hidden' : ''}`}
                                    onLoad={() => handleImageLoaded(video.id_sup)}
                                />
                            </div>
                            <div className="p-4">
                                <p className="bg-blue-200 font-medium inline-block py-1 px-3 text-xs rounded-full text-blue-800 mb-2">
                                    {video.nom_dom}
                                </p>
                                <h2 className="text-xl font-bold text-gray-800 mb-1">{video.nom_sup}</h2>
                                <p className="text-gray-600 text-sm">{video.nom_auteur}</p>
                            </div>
                        </div>
                    ))}
                </div>

                {isVideosPage && totalPages > 1 && (
                    <div className="flex justify-center mt-8">
                        <nav className="inline-flex rounded-lg shadow-sm">
                            <button
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                                className={`p-2 rounded-l-lg border border-r-0
                                    ${currentPage === 1
                                        ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                                        : 'bg-white text-gray-700 hover:bg-gray-50'}`}
                            >
                                <ChevronLeftRoundedIcon />
                            </button>

                            {[...Array(totalPages)].map((_, index) => (
                                <button
                                    key={index + 1}
                                    onClick={() => handlePageChange(index + 1)}
                                    className={`px-4 py-2 border border-r-0
                                        ${currentPage === index + 1
                                            ? 'bg-primary text-white'
                                            : 'bg-white text-gray-700 hover:bg-gray-50'}`}
                                >
                                    {index + 1}
                                </button>
                            ))}

                            <button
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages}
                                className={`p-2 rounded-r-lg border
                                    ${currentPage === totalPages
                                        ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                                        : 'bg-white text-gray-700 hover:bg-gray-50'}`}
                            >
                                <ChevronRightRoundedIcon />
                            </button>
                        </nav>
                    </div>
                )}

                {isHomePage && (
                    <div className="flex justify-center mt-8">
                        <button
                            className="bg-gray-800 text-white font-bold py-3 px-6 rounded-xl
                                     flex items-center gap-2 hover:bg-gray-700 transition-colors"
                            onClick={handleVoirPlusClick}
                        >
                            Voir plus
                            <ChevronRightRoundedIcon />
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CardVideo;
