import './App.css';
import React from "react";
import {BrowserRouter as Router, Route, Routes, useLocation} from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./views/Home";
import Livre from "./views/Livre";
import Video from "./views/Video";
import Category from "./views/Category";
import BookDetail from "./components/bookDetails";
import VideoDetail from "./components/videoDetails";
import Login from "./components/Login";
import Register from "./components/Register";

function App() {
    const location = useLocation();

    // Vérifie si l'utilisateur est sur les pages /login ou /register
    const hideHeaderFooter = location.pathname === "/login" || location.pathname === "/register";

    return (
        <div className="App">
            {!hideHeaderFooter && <Header />}
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/livres" element={<Livre />} />
                <Route path="/categories/:encodedCategory" element={<Category />} />
                <Route path="/videos" element={<Video />} />
                <Route path="/livres/:bookId" element={<BookDetail />} />
                <Route path="/videos/:videoId" element={<VideoDetail />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
            </Routes>
            {!hideHeaderFooter && <Footer />}
        </div>
    );
}

function AppWrapper() {
    return (
        <Router>
            <App />
        </Router>
    );
}

export default AppWrapper;
