import React from 'react';
import MA from "../assets/images/Pub/teamwork.jpg";
import Line from "../assets/images/Decos/Vector 5.png";
import StarBlue from "../assets/images/Decos/StarFour1.png";
import CirclePurple from "../assets/images/Decos/Ellipse 15.png";
import CircleRed from "../assets/images/Decos/Ellipse 3.png";
import CircleBlue from "../assets/images/Decos/Ellipse 16.png";

function Pub() {
    return (
        <div
            className="container mx-auto bg-green-50 mt-10 mb-10 px-4 md:px-8 lg:px-16 xl:px-24 2xl:px-32 3xl:px-[400px] pt-[100px] pb-20 flex justify-between items-center">
            {/* Left section */}
            <div
                className="relative bg-blue-300 rounded-[40px] w-[400px] h-64 before:content-[''] before:absolute before:top-[-15px] before:right-[-15px] before:w-full before:h-full before:border-[1px] before:border-blue-300 before:rounded-[50px] before:box-border before:bg-transparent before:z-[1] hidden md:block">
                <div className="absolute inset-0 z-[2]">
                    <img
                        src={MA}
                        className="w-full h-full object-cover rounded-[40px]"
                        alt="TeamWork"
                    />
                </div>
                <div className="absolute inset-0 z-[1] hidden 3xl:block">
                    <img src={Line} alt="LineBlue" className="absolute ml-[350px] mt-[-30px]"/>
                    <img src={StarBlue} alt="LineBlue" className="absolute -ml-[15px] mt-[-20px]"/>
                    <img src={CirclePurple} alt="LineBlue" className="absolute ml-[390px] mt-[175px]"/>
                    <img src={CircleRed} alt="LineBlue" className="absolute ml-[150px] mt-[-70px]"/>
                    <img src={CircleBlue} alt="LineBlue" className="absolute -ml-[75px] mt-[275px]"/>
                </div>
            </div>
            {/* Right section */}
            <div className="flex-grow p-4 mx-[calc(100px/2)] lg:mx-[calc(100px/2)] xl:mx-[calc(250px/2)]">
                <h1 className="text-4xl mb-4">Rejoignez dès aujourd'hui notre<br/> bibliothèque numérique</h1>
                <p className="text-lg mb-4">Commencez à apprendre en vous inscrivant.</p>
                <button className="bg-gray-800 text-white font-bold py-3 px-5 rounded-[10px]">S'inscrire
                </button>
            </div>
        </div>
    );
}

export default Pub;