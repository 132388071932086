import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {fetchFile, getById} from '../apiConfig/services';
import { ChevronLeft } from "@mui/icons-material";
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import CardVideo from "./CardVideo";
import UnderLine from "../assets/images/Decos/Vector 4.png";
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';

function VideoDetails() {
    const { videoId } = useParams(); // Get book ID from URL
    const [video, setVideo] = useState(null);
    const [coverUrl, setCoverUrl] = useState('');
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedFileUrl, setSelectedFileUrl] = useState(null);
    const [isLoadingPdf, setIsLoadingPdf] = useState(false);

    useEffect(() => {
        if (isModalOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        const fetchVideoDetails = async () => {
            setLoading(true);
            try {
                const videoData = await getById(videoId);
                setVideo(videoData);

                if (videoData.path_fiche1 && videoData.path_fiche1.length > 0) {
                    const coverPromises = videoData.path_fiche1.map(fiche =>
                        fiche.couverture_fiche ? fetchFile(fiche.couverture_fiche, "couverture") : Promise.resolve(null)
                    );

                    const [covers] = await Promise.all([Promise.all(coverPromises)]);
                    setCoverUrl(covers.filter(url => url !== null)[0]); // Assuming only one cover
                }

                setLoading(false);
            } catch (error) {
                console.error('Error fetching video details', error);
                setLoading(false);
            }
        };

        fetchVideoDetails();
    }, [videoId, isModalOpen]);

    const handleBackClick = () => {
        navigate(-1);
    };

    // Function to open PDF in a new tab
    const handleViewPdf = (file, type_fiche) => {
        if (file && type_fiche) {
            setIsLoadingPdf(true); // Affiche le spinner
            fetchFile(file, type_fiche).then(ficheUrl => {
                setSelectedFileUrl(ficheUrl + '#navpanes=0');
                setIsModalOpen(true);
            }).catch(error => {
                console.error("Erreur lors de l'ouverture du fichier :", error);
            }).finally(() => {
                setIsLoadingPdf(false); // Cache le spinner
            });
        } else {
            console.log("Type de fichier non supporté ou fichier manquant.");
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedFileUrl(null);
    };

    // Render the book details
    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="spinner"></div>
            </div>
        );
    }

    if (!video) {
        return <p>Error loading video details.</p>;
    }

    return (
        <div className="container mx-auto mt-10 p-4">
            <button
                onClick={handleBackClick}
                className="mb-5 text-primary py-2 px-4 rounded hover:underline transition duration-300"
            >
                <ChevronLeft className="mr-2" />
                Retour
            </button>
            <div className="bg-primary shadow-lg rounded-lg p-6 flex flex-col md:flex-row items-center">
                <div className="w-full md:w-1/3 mb-4 md:mb-0">
                    {coverUrl ? (
                        <img src={coverUrl} alt={video.nom_sup} className="rounded-lg shadow-md" />
                    ) : (
                        <img src="fallback-image-url.jpg" alt="Fallback" className="rounded-lg shadow-md" />
                    )}
                </div>
                <div className="w-full md:w-2/3 md:pl-10">
                    <h1 className="text-3xl font-bold mb-4 text-blue-300">{video.nom_sup}</h1>
                    <p className="text-lg mb-2 text-gray-400">{video.nom_dom}</p>
                    <p className="text-lg font-normal mb-2 text-blue-300">Par: <span className="font-normal underline">{video.nom_auteur}</span></p>
                    <p className="text-lg mb-6 font-bold text-white">Langue: <span className="font-normal">{video.langue_sup}</span></p>
                    <p className="text-white leading-relaxed mb-4">{video.desc_sup || 'No description available.'}</p>

                    {video.mot_cle && (
                        <div className="mb-4">
                            <h3 className="text-white font-semibold mb-2">Mots-clés:</h3>
                            <p className="text-white">{video.mot_cle}</p>
                        </div>
                    )}

                    {video.path_fiche1 && video.path_fiche1.length > 0 && (
                        <div className="mb-4">
                            <ul>
                                {video.path_fiche1.map((fiche, index) => (
                                    <li key={fiche.id_fiche} className="flex items-center justify-between mb-2">
                                        {/* Utilisation de flex pour aligner la numérotation et l'icône de lecture côte à côte */}
                                        <div className="flex items-center">
                                            {/* Afficher la numérotation */}
                                            <span className="text-lg text-white mr-2">{fiche.title_fiche}</span>

                                            {/* Bouton avec l'icône de lecture */}
                                            <button
                                                onClick={() => handleViewPdf(fiche.file, fiche.type_fiche)}
                                                className="ml-2"
                                            >
                                                <PlayArrowRoundedIcon style={{ fontSize: 70 }} className="text-blue-300" />
                                            </button>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            </div>
            <h1 className="text-3xl font-bold mt-5">Suggestions</h1>
            <img src={UnderLine} alt="Underline decoration" className="ml-2"/>
            <CardVideo/>

            {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50 overflow-y-auto">
                        <button onClick={closeModal} className="absolute top-2 right-2 text-gray-500 hover:text-red-500">
                            <HighlightOffRoundedIcon />
                        </button>
                    <div className="bg-white rounded-lg p-2 relative w-full md:w-3/4 lg:w-4/5 xl:w-2/3 h-3/4 overflow-y-auto">
                        {isLoadingPdf ? (
                            <div className="flex justify-center items-center h-full">
                                <div className="spinner"/>
                            </div>
                        ) : (
                            <iframe
                                src={selectedFileUrl}
                                frameBorder="0"
                                className="w-full h-full"
                                title="PDF Viewer"
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

export default VideoDetails;
