import React from 'react';
import Search from "./Search";
import UnderLine from "../assets/images/Decos/Vector 4.png";
import CircleBlue from "../assets/images/Decos/Ellipse 6.png";
import StarGreen from "../assets/images/Decos/Sparkle.png";
import CirclePurple from "../assets/images/Decos/Ellipse 7.png";
import StarBlue from "../assets/images/Decos/StarFour.png";
import StarRed from "../assets/images/Decos/Frame 16.png";
import CircleRed from "../assets/images/Decos/Ellipse 3.png";
import Line from "../assets/images/Decos/Vector 3.png";
import Blur from "../assets/images/Decos/Blur White.png";

function Title() {
    return (
        <div className="bg-primary text-center pt-24 md:pt-10 sm:pt-10 xs:pt-10 pb-48 md:pb-40 sm:pb-10 xs:pb-10">
            {/* Décorations visibles uniquement sur les écrans 2xl et plus */}
            <div className="deco hidden 3xl:block my-16">
                <img src={CircleBlue} alt="CircleBlue decoration" className="ml-[1570px] -mt-[80px]" />
                <img src={CirclePurple} alt="CirclePurple decoration" className="absolute ml-[725px] top-33" />
                <img src={StarBlue} alt="StarBlue decoration" className="absolute ml-[1010px] top-40" />
                <img src={Line} alt="Line decoration" className="absolute ml-[1750px] top-80" />
                <img src={StarGreen} alt="StarGreen decoration" className="absolute ml-[170px] top-64" />
                <img src={StarRed} alt="StarRed decoration" className="absolute ml-[1750px] top-[600px]" />
                <img src={CircleRed} alt="CircleRed decoration" className="absolute ml-[50px] top-[550px]" />
                <img src={CircleBlue} alt="CircleBlue decoration" className="absolute ml-[1350px] top-[550px]" />
                <img src={CirclePurple} alt="CirclePurple decoration" className="absolute ml-[875px] top-[725px]" />
                <img src={StarBlue} alt="StarBlue decoration" className="absolute ml-[425px] top-[650px]" />
            </div>

            {/* Titre */}
            <div className="relative">
                <h1 className="text-xl sm:text-4xl md:text-4xl lg:text-3xl xl:text-3xl 2xl:text-6xl mx-10 sm:mx-20 md:mx-28 lg:mx-40 text-white pt-8 md:pt-10 relative">
                    {/* Décoration flou visible uniquement sur les écrans 2xl et plus */}
                    <div className="deco hidden 3xl:block absolute inset-0 flex -top-32 items-center justify-center">
                        <img src={Blur} alt="Blur background" className="" />
                    </div>
                    Bienvenue sur la
                    <span className="text-blue-300"> Bibliothèque Numérique
                        {/* Ligne de décoration visible uniquement sur les écrans 2xl et plus */}
                        <div className="deco hidden 3xl:block">
                            <img src={UnderLine} alt="Underline decoration"
                                className="flex space-x-48 mt-2 ml-[775px] -mt-3" />
                            <img src={UnderLine} alt="Underline decoration"
                                className="flex space-x-48 -mt-0 ml-[1100px] -mt-3" />
                        </div>
                    </span> de l'Ecole de Management et d'Innovation Technologique
                </h1>
            </div>


            {/* Paragraphe avec marge conditionnelle en fonction de la présence des décorations */}
            <p className="text-gray-400 mt-6 mb-16 2xl:mt-16">
                Votre espace dédié à l'excellence académique en Informatique, Management et Relation Publique et
                Multimédia
            </p>

            {/* Composant de recherche avec marge conditionnelle */}
            <Search />
        </div>
    );
}

export default Title;
