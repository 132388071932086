import React from 'react'
import Videos from "../components/Videos";
import VideoHeader from "../components/views_title/VideoHeader";

function Video() {
    return (
        <div>
            <VideoHeader />
            <Videos />
        </div>
    )
}

export default Video
