import React, { useState, useEffect } from 'react';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import SlideshowOutlinedIcon from '@mui/icons-material/SlideshowOutlined';
import { getAllDomaines, getCount } from "../apiConfig/services";
import { useNavigate } from "react-router-dom";
import { ShimmerThumbnail } from 'react-shimmer-effects';

function CardCategory() {
    const [cardData, setCardData] = useState([]);
    const [loading, setLoading] = useState(true); // État pour gérer le chargement
    const [showMore, setShowMore] = useState(false); // État pour afficher plus de cartes
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCategoryAndCount = async () => {
            try {
                const categories = await getAllDomaines();
                const counts = await getCount();

                // Fusionner les catégories avec leurs statistiques
                const mergedData = categories.map(cat => {
                    const count = counts.find(countItem => countItem.id_dom === cat.id_dom);
                    return {
                        ...cat,
                        total_livres: count?.total_livres || 0,
                        total_videos: count?.total_videos || 0,
                    };
                });

                setCardData(mergedData);
            } catch (error) {
                console.error('Erreur lors de la récupération des catégories et des nombres', error);
            } finally {
                setLoading(false); // Arrêter le chargement après la récupération des données
            }
        };

        fetchCategoryAndCount();
    }, []);

    const handleNavigationPage = (categoryNomDomaine) => () => {
        const encodedCategory = encodeURIComponent(categoryNomDomaine);
        navigate(`/categories/${encodedCategory}`);
    };

    const handleShowMore = () => {
        setShowMore(!showMore); // Inverse l'état pour afficher plus ou moins de cartes
    };

    // Limitez les cartes à 6 si showMore est faux
    const displayedCards = showMore ? cardData : cardData.slice(0, 6);

    return (
        <div className="container mt-10">
            {loading ? (
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
                    <ShimmerThumbnail height={310} rounded/>
                    <ShimmerThumbnail height={310} rounded/>
                    <ShimmerThumbnail height={310} rounded/>
                </div>
            ) : (
                <>
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
                        {displayedCards.map((card, index) => (
                            <div
                                key={index}
                                className="bg-blue-50 rounded-xl overflow-hidden shadow-sm transition-all duration-200 hover:shadow-md hover:-translate-y-1 cursor-pointer"
                                onClick={handleNavigationPage(card.nom_dom)}>
                                <div className="p-4">
                                    <div className="text-center mt-5 pb-10">
                                        <SchoolRoundedIcon style={{ fontSize: 100 }} className="text-blue-300" />
                                    </div>
                                    <h2 className="text-center text-2xl font-bold mb-2">{card.nom_dom}</h2>
                                    <div className="flex items-center justify-center space-x-2.5 mb-4">
                                        <div className="relative">
                                            <span className="absolute -top-0.5 h-2 w-2 bg-red-500 rounded-full"></span>
                                        </div>
                                        <span className="text-gray-700">{card.total_livres} Livres</span>
                                        <SlideshowOutlinedIcon className="text-blue-300" />
                                        <span className="text-gray-700">{card.total_videos} Vidéos</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {/* Affiche le bouton "Voir plus" uniquement si le nombre de cartes dépasse 6 */}
                    {cardData.length > 6 && (
                        <div className="text-center mt-4">
                            <button
                                className="bg-gray-800 text-white font-bold py-3 px-5 rounded-[10px]"
                                onClick={handleShowMore}
                            >
                                {showMore ? 'Voir moins' : 'Voir plus'}
                            </button>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

export default CardCategory;
