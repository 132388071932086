import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EmiLib from '../assets/images/Logos/EmiLib.png'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import TagOutlinedIcon from '@mui/icons-material/TagOutlined';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

const Login = () => {
    const navigate = useNavigate();
    const [immatricule, setImmatricule] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');

    const handleGoBack = () => {
        navigate("/");
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!immatricule || !password) {
            setError('Veuillez remplir tous les champs');
            return;
        }

        try {
            // Logique de connexion à implémenter
            console.log('Tentative de connexion');
            navigate('/')
        } catch (err) {
            setError('Identifiants incorrects');
        }
    };

    return (
        <div className="min-h-screen flex">
            {/* Colonne de gauche - Contenu informatif */}
            <div className="hidden lg:flex lg:w-1/2 bg-primary text-white p-12 flex-col justify-center">
                <div className="max-w-md">
                    <button
                        onClick={handleGoBack}
                        className="flex items-center text-white hover:text-blue-300 mb-6"
                    >
                        <ArrowBackIosRoundedIcon className="mr-2"/> Retour
                    </button>
                    <h1 className="text-4xl font-bold mb-6">Bienvenue sur</h1>
                    <img src={EmiLib} alt="EmiLib" className="h-12 w-auto cursor-pointer mb-4 mx-auto md:mx-0"/>
                    <p className="text-xl mb-8 leading-relaxed">
                        Votre bibliothèque numérique pour accéder à des milliers de livres, où que vous soyez.
                    </p>
                    <p className="text-xl mb-8 leading-relaxed">
                        Connectez-vous pour découvrir une vaste collection de ressources littéraires et culturelles.
                    </p>
                    <div className="space-y-4">
                        <div className="flex items-center">
                            <div className="w-10 h-10 bg-white/20 rounded-full flex items-center justify-center mr-4">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none"
                                     viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                          d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                </svg>
                            </div>
                            <span>Accès sécurisé</span>
                        </div>
                        <div className="flex items-center">
                            <div className="w-10 h-10 bg-white/20 rounded-full flex items-center justify-center mr-4">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none"
                                     viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                          d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                </svg>
                            </div>
                            <span>Gestion en temps réel</span>
                        </div>
                    </div>
                </div>
            </div>

            {/* Colonne de droite - Formulaire de connexion */}
            <div className="w-full lg:w-1/2 flex items-center justify-center p-8">
                <div className="w-full max-w-md  p-8">
                <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">
                        Connexion
                    </h2>

                    <form onSubmit={handleSubmit} className="space-y-4">
                        {error && (
                            <div className="bg-red-50 border border-red-300 text-red-700 px-4 py-3 rounded relative" role="alert">
                                {error}
                            </div>
                        )}

                        <div className="relative">
                            <label htmlFor="immatricule" className="block text-sm font-medium text-gray-700 mb-2">
                                Immatricule
                            </label>
                            <div className="relative">
                                <TagOutlinedIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                                <input
                                    type="text"
                                    id="immatricule"
                                    value={immatricule}
                                    onChange={(e) => setImmatricule(e.target.value)}
                                    placeholder="Entrez votre immatricule"
                                    className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-300"
                                    required
                                />
                            </div>
                        </div>

                        <div className="relative">
                            <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-2">
                                Mot de passe
                            </label>
                            <div className="relative">
                                <LockOutlinedIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                                <input
                                    type={showPassword ? "text" : "password"}
                                    id="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Entrez votre mot de passe"
                                    className="w-full pl-10 pr-10 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-300"
                                    required
                                />
                                <button
                                    type="button"
                                    onClick={() => setShowPassword(!showPassword)}
                                    className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
                                >
                                    {showPassword ? <VisibilityOffOutlinedIcon fontSize="small" /> : <VisibilityOutlinedIcon fontSize="small" />}
                                </button>
                            </div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-blue-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-300"
                            >
                                Se connecter
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;