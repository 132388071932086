// Videos.js
import React, { useState } from 'react';
import CardVideo from "./CardVideo";
import UnderLine from "../assets/images/Decos/Vector 4.png";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { useNavigate } from "react-router-dom";

function Videos() {
    const [hasVideos, setHasVideos] = useState(true);
    const navigate = useNavigate();

    const handleVoirPlusClick = () => {
        navigate('/videos');
    };

    if (!hasVideos) {
        return null; // Ou un message alternatif si besoin
    }

    return (
        <div className="container mx-auto px-4 py-4 mt-10 lg:px-[10%]" id="videos">
            <h1 className="text-4xl">Vidéos recommandées</h1>
            <img src={UnderLine} alt="Underline decoration" className="ml-16"/>
            <CardVideo setHasVideos={setHasVideos} />
        </div>
    );
}

export default Videos;
