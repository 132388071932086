import React from 'react'
import Search from "../Search";

function VideoHeader() {
    return (
        <div>
            <div className="bg-primary p-10">
                <h1 className="text-5xl text-blue-300 text-center mb-10">Tous les vidéos</h1>
            </div>
            <Search/>
        </div>

    )
}

export default VideoHeader
