import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchFile, getById } from '../apiConfig/services';
import { ChevronLeft } from "@mui/icons-material";
import PlayLessonOutlinedIcon from '@mui/icons-material/PlayLessonOutlined';
import CardBook from "./CardBook";
import UnderLine from "../assets/images/Decos/Vector 4.png";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";

function BookDetail() {
    // États et hooks
    const { bookId } = useParams();
    const navigate = useNavigate();
    const [state, setState] = useState({
        book: null,
        coverUrl: '',
        loading: true
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedFileUrl, setSelectedFileUrl] = useState(null);
    const [isLoadingPdf, setIsLoadingPdf] = useState(false);

    // Gestionnaires d'événements
    const handleBackClick = () => navigate(-1);

    const handleViewPdf = (file, type_fiche) => {
        if (file && type_fiche) {
            setIsLoadingPdf(true); // Affiche le spinner
            fetchFile(file, type_fiche).then(ficheUrl => {
                setSelectedFileUrl(ficheUrl + '#toolbar=0&navpanes=1&view=FitH&zoom=100');

                setIsModalOpen(true);
            }).catch(error => {
                console.error("Erreur lors de l'ouverture du fichier :", error);
            }).finally(() => {
                setIsLoadingPdf(false); // Cache le spinner
            });
        } else {
            console.log("Type de fichier non supporté ou fichier manquant.");
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedFileUrl(null);
    };

    // Effet de chargement des données
    useEffect(() => {
        const fetchBookDetails = async () => {
            try {
                const bookData = await getById(bookId);
                let coverUrl = '';

                if (bookData.path_fiche1?.length > 0) {
                    const covers = await Promise.all(
                        bookData.path_fiche1.map(fiche =>
                            fiche.couverture_fiche ? fetchFile(fiche.couverture_fiche, "couverture") : null
                        )
                    );
                    coverUrl = covers.filter(url => url !== null)[0] || '';
                }

                setState({
                    book: bookData,
                    coverUrl,
                    loading: false
                });
            } catch (error) {
                console.error('Error fetching book details', error);
                setState(prev => ({ ...prev, loading: false }));
            }
        };

        fetchBookDetails();
    }, [bookId]);

    // Désactiver le défilement en arrière-plan lorsque la modal est ouverte
    useEffect(() => {
        if (isModalOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [isModalOpen]);

    // Composants d'interface conditionnels
    if (state.loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="spinner"/>
            </div>
        );
    }

    if (!state.book) {
        return <p>Error loading book details.</p>;
    }

    // Sous-composants
    const BookHeader = () => (
        <div className="container mx-auto relative px-4 z-20">
            <button
                onClick={handleBackClick}
                className="absolute top-4 left-4 2xl:left-48 font-bold text-sm text-white"
            >
                <ChevronLeft/>
                Retour
            </button>
            <div className="flex flex-col mb-20 ml-4 2xl:ml-[605px] pt-20 h-full">
                <h1 className="text-2xl sm:text-3xl md:text-4xl text-blue-300 mt-4">
                    {state.book.nom_sup}
                </h1>
                <ReadButton/>
                <h1 className="text-md text-gray-300 mt-4">{state.book.nom_dom}</h1>
                <h1 className="text-md text-blue-300 mt-4">
                    Par : <span className="underline">{state.book.nom_auteur}</span>
                </h1>
                <p className="text-white mt-2">{state.book?.author}</p>
            </div>
        </div>
    );

    const ReadButton = () => (
        <div className="absolute top-28 right-4 2xl:right-48 sm:block">
            {state.book.path_fiche1 && state.book.path_fiche1.map((fiche, index) => (
                <div key={index} className="text-blue-300 hover:cursor-pointer hover:text-blue-500 mb-2">
                    <PlayLessonOutlinedIcon
                        fontSize="large"
                        onClick={() => handleViewPdf(fiche.file, fiche.type_fiche)}
                    />
                    <span className="text-md font-semibold hidden sm:inline md:inline lg:inline xl:inline 2xl:inline">{fiche.title_fiche} ({fiche.type_fiche})</span>
                </div>
            ))}
        </div>
    );


    const BookCover = () => state.coverUrl && (
        <div className="hidden 2xl:block absolute top-24 left-48 transform z-10">
            <img
                src={state.coverUrl}
                alt="Book Cover"
                className="w-[400px] h-[520px] object-cover rounded-lg shadow-lg"
            />
        </div>
    );

    const BookDescription = () => (
        <div className="container mx-auto relative px-4">
            <p className="ml-4 2xl:ml-[590px] font-semibold text-justify py-4">
                {state.book.desc_sup}
            </p>
            <div className="ml-4 2xl:ml-[590px] mt-4 flex flex-wrap gap-2">
                <span className="font-bold">Mot-clés :</span>
                {state.book.mot_cle.split(',').map((mot, index) => (
                    <span
                        key={index}
                        className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-1 rounded-full"
                    >
                        {mot.trim()}
                    </span>
                ))}
            </div>
        </div>
    );

    const Suggestions = () => (
        <div className="container mx-auto px-4">
            <h1 className="text-xl sm:text-2xl md:text-3xl font-bold">Suggestions</h1>
            <img src={UnderLine} alt="Underline decoration hidden md:block" className="ml-2"/>
            <CardBook/>
        </div>
    );

    // Rendu principal
    return (
        <div className="relative">
            <div className="min-h-screen">
                <div className="w-full h-64 relative">
                    {state.coverUrl && (
                        <div className="absolute inset-0 2xl:hidden">
                            <div className="absolute inset-0 bg-black/50 z-10"/>
                            <img
                                src={state.coverUrl}
                                alt=""
                                className="w-full h-full object-cover"
                            />
                        </div>
                    )}
                    <div className="hidden 2xl:block absolute inset-0 bg-primary"/>
                    <BookHeader/>
                </div>
                <BookCover/>
                <BookDescription/>
            </div>
            {/* <Suggestions/>

             Modale pour afficher le fichier PDF */}
            {isModalOpen && (
                <div
                    className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50 overflow-y-auto">
                    <button onClick={closeModal} className="absolute top-2 right-2 text-gray-500 hover:text-red-500">
                        <HighlightOffRoundedIcon/>
                    </button>
                    <div
                        className="bg-white rounded-lg p-2 relative w-full md:w-3/4 lg:w-4/5 xl:w-2/3 h-3/4 overflow-y-auto">
                        {isLoadingPdf ? (
                            <div className="flex justify-center items-center h-full">
                                <div className="spinner"/>
                            </div>
                        ) : (
                            <iframe
                                src={selectedFileUrl}
                                frameBorder="0"
                                className="w-full h-full"
                                title="PDF Viewer"
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

export default BookDetail;