import React from 'react';
import CardCategory from "./CardCategory";
import UnderLine from "../assets/images/Decos/Vector 4.png";
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { useNavigate } from "react-router-dom";

function Category() {
    const navigate = useNavigate();
    const handleVoirPlusClick = () => {
        navigate('/categories');
    };

    return (
        <div className="container mx-auto px-4 py-4 mt-10 lg:px-[10%]" id="category">
            <h1 className="text-4xl">Meilleures Catégories</h1>
            <img src={UnderLine} alt="Underline decoration" className="ml-16"/>
            <CardCategory/>
            <div className="flex justify-center mt-5">
                {/*<button className="bg-gray-800 text-white font-bold py-3 pl-3 rounded-[10px] flex items-center"*/}
                {/*        onClick={handleVoirPlusClick}>*/}
                {/*    Voir plus*/}
                {/*    <ChevronRightRoundedIcon className="ml-2"/>*/}
                {/*</button>*/}
            </div>
        </div>
    );
}

export default Category;
