import React from 'react'
import Title from "../components/Title";
import CardMention from "../components/CardMention";
import Category from "../components/Category";
import Book from "../components/Book";
import Videos from "../components/Videos";
import Pub from "../components/Pub";

function Home() {
    return (
        <div className="App">
            <Title/>
            <div className="hidden sm:hidden md:hidden xl:block">
                <CardMention/>
            </div>
            <Category/>
            <Book/>
            <Videos/>
            <Pub/>
        </div>
    )
}

export default Home
