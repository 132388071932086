import React, { useState, useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import { getAllDomaines } from '../apiConfig/services'; // Importer la fonction getAllDomaines
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

function Search() {
    const [isFocused, setIsFocused] = useState(false);
    const [categories, setCategories] = useState([]); // État pour stocker les catégories
    const [selectedCategory, setSelectedCategory] = useState('');

    // Utiliser useEffect pour récupérer les catégories dès le montage du composant
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const data = await getAllDomaines();
                setCategories(data); // Stocker les catégories récupérées dans l'état
            } catch (error) {
                console.error('Erreur lors de la récupération des catégories', error);
            }
        };

        fetchCategories();
    }, []);

    return (
        <div className="flex flex-col items-center justify-center pt-8 pb-16 bg-primary px-2 md:px-0">
            {/* Sélecteur de catégorie pour les écrans inférieurs à sm */}
            <div className="relative w-full max-w-4xl mx-auto mb-4 sm:hidden">
                <div className="relative group">
                    <select
                        className="w-full h-14 pl-12 pr-10 bg-white/10
                     text-white rounded-full border-2 border-blue-200 focus:ring-blue-200
                     outline-none appearance-none transition-all duration-300
                     hover:border-blue-200"
                        value={selectedCategory}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                    >
                        <option value="" disabled>
                            Sélectionner une catégorie
                        </option>
                        {categories.map((category) => (
                            <option
                                key={category.id_dom}
                                value={category.id_dom}
                                className="text-gray-900 bg-white py-2"
                            >
                                {category.nom_dom}
                            </option>
                        ))}
                    </select>

                    {/* Category Icon */}
                    <div className="absolute left-4 top-1/2 -translate-y-1/2 text-white/70">
                        <CategoryOutlinedIcon className="text-white mr-2 lg:hidden" />
                    </div>

                    {/* Chevron Icon */}
                    <div className="absolute right-4 top-1/2 -translate-y-1/2 text-white/70 pointer-events-none">
                        <KeyboardArrowDownRoundedIcon className="w-5 h-5" />
                    </div>
                </div>
            </div>

            {/* Champ de recherche et sélecteur pour les écrans supérieurs à sm */}
            <div
                className="relative flex items-center bg-blue-100 bg-opacity-15 rounded-full border-2 border-blue-300 w-full max-w-4xl mx-auto sm:flex md:mx-12 lg:mx-12" // Ajout de marges à gauche et à droite pour les écrans supérieurs à md
            >
                <div className="relative flex items-center px-4 py-2 bg-transparent text-white border-none rounded-l-full h-16 flex-shrink-0 hidden sm:flex">
                    <CategoryOutlinedIcon className="text-white mr-2 lg:hidden" />
                    <select
                        className={`bg-transparent text-white outline-none h-full px-4 py-2 rounded-l-full transition-all duration-300 ${isFocused ? 'select-expanded' : ''
                            }`}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                        value={selectedCategory}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                        style={{ width: '150px' }}
                    >
                        <option value="" className="text-gray-700 hidden lg:block" disabled selected>
                            Catégorie
                        </option>
                        {/* Remplacer les options statiques par les catégories récupérées */}
                        {categories.map((category) => (
                            <option key={category.id_dom} value={category.id_dom} className="text-gray-900 bg-white hover:bg-blue-100">
                                {category.nom_dom}
                            </option>
                        ))}
                    </select>
                </div>

                {/* Champ de recherche */}
                <input
                    type="search"
                    placeholder="Rechercher un livre..."
                    className="p-6 flex-grow border-none bg-transparent text-white placeholder-gray-300 h-16"
                />

                {/* Bouton de recherche */}
                <button className="flex items-center px-4 py-2 bg-blue-300 text-gray-800 rounded-r-full font-bold h-16 flex-shrink-0">
                    <SearchIcon className="text-gray-800" />
                    <span className="ml-2 hidden md:inline">Rechercher</span>
                </button>
            </div>
        </div>
    );
}

export default Search;
