import React, { useState } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom'; // Importer useNavigate
import Logo from '../assets/images/Logos/EmiLib.png';
import { Link as ScrollLink } from 'react-scroll';
import HttpsRoundedIcon from '@mui/icons-material/HttpsRounded';
import ScrollToTop from './ScrollToTop';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [userStatus, setUserStatus] = useState('déconnecté'); // Variable pour le statut de l'utilisateur
    const location = useLocation();
    const navigate = useNavigate(); // Initialiser useNavigate
    const isHome = location.pathname === '/' || location.pathname === '/home';

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleLoginClick = () => {
        setUserStatus('connecté'); // Mise à jour du statut à "connecté" lors de la connexion
        navigate('/login'); // Rediriger vers la page /auth
    };

    const handleRegisterClick = () => {
        navigate('/register'); // Rediriger vers la page /auth
    };

    const handleLogoutClick = () => {
        setUserStatus('déconnecté'); // Mise à jour du statut à "déconnecté" lors de la déconnexion
        navigate('/'); // Rediriger vers la page d'accueil
    };

    return (
        <header className="bg-primary text-white transition-all duration-500 ease-in-out">
            <div className="container mx-auto px-4 py-4 flex justify-between items-center flex-wrap lg:px-[10%]">
                {/* Logo */}
                <div className="flex items-center w-full md:w-auto justify-between md:justify-start">
                    <Link to="/" className="flex-grow md:flex-grow-0 text-center md:text-left">
                        <img
                            src={Logo}
                            alt="Logo"
                            className="h-12 w-auto cursor-pointer mx-auto md:mx-0 transition-transform duration-500 ease-in-out hover:scale-110"
                        />
                    </Link>
                    <button
                        className="md:hidden text-white focus:outline-none"
                        onClick={toggleMenu}
                    >
                        {isMenuOpen ? (
                            <CloseIcon
                                fontSize="large"
                                className="transition-transform duration-500 ease-in-out transform rotate-180"
                            />
                        ) : (
                            <MenuIcon
                                fontSize="large"
                                className="transition-transform duration-500 ease-in-out"
                            />
                        )}
                    </button>
                </div>

                {/* Navigation */}
                <nav
                    className={`${
                        isMenuOpen ? 'block' : 'hidden'
                    } md:flex md:flex-row md:space-x-6 w-full md:w-auto ${
                        isHome ? 'md:mx-auto md:justify-start' : 'md:justify-end'
                    } mt-4 md:mt-0 flex flex-col md:flex-row`}
                >
                    {isHome ? (
                        <>
                            <ScrollLink
                                to="category"
                                smooth={true}
                                duration={500}
                                className="text-gray-200 cursor-pointer py-2 md:py-0 text-center block md:inline transition-colors duration-300 ease-in-out hover:text-white"
                                onClick={() => setIsMenuOpen(false)}
                            >
                                Catégories
                            </ScrollLink>
                            <ScrollLink
                                to="book"
                                smooth={true}
                                duration={500}
                                className="text-gray-200 cursor-pointer py-2 md:py-0 text-center block md:inline transition-colors duration-300 ease-in-out hover:text-white"
                                onClick={() => setIsMenuOpen(false)}
                            >
                                Livres
                            </ScrollLink>
                            <ScrollLink
                                to="videos"
                                smooth={true}
                                duration={500}
                                className="text-gray-200 cursor-pointer py-2 md:py-0 text-center block md:inline transition-colors duration-300 ease-in-out hover:text-white"
                                onClick={() => setIsMenuOpen(false)}
                            >
                                Vidéos
                            </ScrollLink>
                        </>
                    ) : (
                        <>
                            <Link
                                to="/"
                                className="text-gray-200 cursor-pointer py-2 md:py-0 text-center block md:inline transition-colors duration-300 ease-in-out hover:text-white"
                                onClick={() => setIsMenuOpen(false)}
                            >
                                Accueil
                            </Link>
                            <Link
                                to="/livres"
                                className="text-gray-200 cursor-pointer py-2 md:py-0 text-center block md:inline transition-colors duration-300 ease-in-out hover:text-white"
                                onClick={() => setIsMenuOpen(false)}
                            >
                                Livres
                            </Link>
                            <Link
                                to="/videos"
                                className="text-gray-200 cursor-pointer py-2 md:py-0 text-center block md:inline transition-colors duration-300 ease-in-out hover:text-white"
                                onClick={() => setIsMenuOpen(false)}
                            >
                                Vidéos
                            </Link>
                        </>
                    )}
                </nav>

                {/* Actions (boutons de connexion et d'inscription) */}
                <div
                    className={`${
                        isMenuOpen ? 'block' : 'hidden'
                    } md:flex md:flex-row space-y-2 md:space-y-0 md:space-x-4 w-full md:w-auto justify-center md:justify-end mt-4 md:mt-0 flex flex-col md:flex-row`}
                >
                    {userStatus === 'déconnecté' ? (
                        <>
                            <button
                                className="text-gray-200 py-2 px-4 rounded flex items-center justify-center w-full md:w-auto transition-colors duration-300 ease-in-out hover:text-white"
                                onClick={handleLoginClick} // Gestionnaire pour le clic
                            >
                                <HttpsRoundedIcon />
                                <span className="ml-2">Se connecter</span>
                            </button>
                            <button
                                className="bg-blue-300 text-gray-800 py-2.5 px-6 rounded-[50px] flex items-center justify-center w-full md:w-auto mt-2 md:mt-0 transition-colors duration-300 ease-in-out hover:bg-blue-400"
                                onClick={handleRegisterClick}
                            >
                                S'inscrire
                            </button>
                        </>
                    ) : (
                        <button
                            className="text-gray-200 py-2 px-4 rounded flex items-center justify-center w-full md:w-auto transition-colors duration-300 ease-in-out hover:text-white"
                            onClick={handleLogoutClick} // Gestionnaire pour le clic
                        >
                            Se déconnecter
                        </button>
                    )}
                </div>
            </div>
            <ScrollToTop />
        </header>
    );
}

export default Header;
