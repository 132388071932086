import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EmiLib from '../assets/images/Logos/EmiLib.png'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PersonOutlined from '@mui/icons-material/PersonOutlined';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import TagOutlinedIcon from "@mui/icons-material/TagOutlined";

const Register = () => {
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
        im_stud: '',
        firt_name_stud: '',
        last_name_stud: '',
        password_stud: '',
        confirmPassword_stud: ''
    });
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [error, setError] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleFirstStepSubmit = (e) => {
        e.preventDefault();
        // Validation pour la première étape
        if (formData.im_stud <= 7 || formData.im_stud >= 4 ) {
            setError('Le numéro matricule doit être entre 4 et 6 caractères');
            return;
        }
        setError('');
        setStep(2);
    };

    const handleFinalSubmit = (e) => {
        e.preventDefault();

        if (formData.password_stud !== formData.confirmPassword_stud) {
            setError('Les mots de passe ne correspondent pas');
            return;
        }

        try {
            // Logique d'inscription à implémenter
            console.log('Inscription', formData);
            navigate('/login')
        } catch (err) {
            setError('Erreur lors de l\'inscription');
        }
    };

    const handleGoBack = () => {
        navigate("/");
    };

    const renderFirstStep = () => (
        <form className="space-y-4">
            {error && (
                <div className="bg-red-50 border border-red-300 text-red-700 px-4 py-3 rounded relative" role="alert">
                    {error}
                </div>
            )}

            <div className="relative">
                <label htmlFor="im_stud" className="block text-sm font-medium text-gray-700 mb-2">
                    Numéro immatricule
                </label>
                <div className="relative">
                    <TagOutlinedIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    <input
                        type="text"
                        id="im_stud"
                        name="im_stud"
                        value={formData.im_stud}
                        onChange={handleChange}
                        placeholder="Entrez votre immatricule"
                        className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-300"
                        required
                    />
                </div>
            </div>

            <div>
                <button
                    onClick={handleFirstStepSubmit}
                    type="submit"
                    className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-blue-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-300"
                >
                    Suivant
                </button>
            </div>
        </form>
    );

    const renderSecondStep = () => (
        <form onSubmit={handleFinalSubmit} className="space-y-4">
            {error && (
                <div className="bg-red-50 border border-red-300 text-red-700 px-4 py-3 rounded relative" role="alert">
                    {error}
                </div>
            )}

            <div className="relative">
                <label htmlFor="firt_name_stud" className="block text-sm font-medium text-gray-700 mb-2">
                    Nom
                </label>
                <div className="relative">
                    <PersonOutlined className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    <input
                        type="firt_name_stud"
                        id="firt_name_stud"
                        name="firt_name_stud"
                        value={formData.firt_name_stud}
                        onChange={handleChange}
                        disabled
                        className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-300"
                    />
                </div>
            </div>

            <div className="relative">
                <label htmlFor="last_name_stud" className="block text-sm font-medium text-gray-700 mb-2">
                    Prénom
                </label>
                <div className="relative">
                    <PersonOutlined className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    <input
                        type="text"
                        id="last_name_stud"
                        name="last_name_stud"
                        value={formData.last_name_stud}
                        onChange={handleChange}
                        className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-300"
                        disabled
                    />
                </div>
            </div>

            <div className="relative">
                <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-2">
                    Mot de passe
                </label>
                <div className="relative">
                    <LockOutlinedIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    <input
                        type={showPassword ? "text" : "password"}
                        id="password_stud"
                        name="password_stud"
                        value={formData.password_stud}
                        onChange={handleChange}
                        placeholder="Créez un mot de passe"
                        className="w-full pl-10 pr-10 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-300"
                        required
                    />
                    <button
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                        className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
                    >
                        {showPassword ? <VisibilityOffOutlinedIcon fontSize="small" /> : <VisibilityOutlinedIcon fontSize="small" />}
                    </button>
                </div>
            </div>

            <div className="relative">
                <label htmlFor="confirmPassword_stud" className="block text-sm font-medium text-gray-700 mb-2">
                    Confirmer le mot de passe
                </label>
                <div className="relative">
                    <LockOutlinedIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    <input
                        type={showConfirmPassword ? "text" : "password"}
                        id="confirmPassword_stud"
                        name="confirmPassword_stud"
                        value={formData.confirmPassword_stud}
                        onChange={handleChange}
                        placeholder="Confirmez votre mot de passe"
                        className="w-full pl-10 pr-10 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-300"
                        required
                    />
                    <button
                        type="button"
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
                    >
                        {showConfirmPassword ? <VisibilityOffOutlinedIcon fontSize="small" /> : <VisibilityOutlinedIcon fontSize="small" />}
                    </button>
                </div>
            </div>

            <div className="flex space-x-4">
                <button
                    type="button"
                    onClick={() => setStep(1)}
                    className="w-1/2 flex justify-center py-3 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-300"
                >
                    Retour
                </button>
                <button
                    type="submit"
                    className="w-1/2 flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-blue-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-300"
                >
                    S'inscrire
                </button>
            </div>
        </form>
    );

    return (
        <div className="min-h-screen flex">
            {/* Colonne de droite - Contenu informatif */}
            <div
                className="hidden lg:flex lg:w-1/2 bg-primary text-white p-12 flex-col justify-center">
                <div className="max-w-md">
                    <button
                        onClick={handleGoBack}
                        className="flex items-center text-white hover:text-blue-300 mb-6"
                    >
                        <ArrowBackIosRoundedIcon className="mr-2"/> Retour
                    </button>
                    <h1 className="text-4xl font-bold mb-6">Rejoignez</h1>
                    <img src={EmiLib} alt="EmiLib" className="h-12 w-auto cursor-pointer mb-4 mx-auto md:mx-0"/>

                    <p className="text-xl mb-8 leading-relaxed">
                        Découvrez un univers littéraire sans limites.
                    </p>
                    <p className="text-xl mb-8 leading-relaxed">
                        Créez votre compte gratuitement et accédez à des milliers de livres numériques, magazines, et plus encore !
                    </p>
                    <div className="space-y-4">
                        <div className="flex items-center">
                            <div className="w-10 h-10 bg-white/20 rounded-full flex items-center justify-center mr-4">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none"
                                     viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                          d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                </svg>
                            </div>
                            <span>Processus simple et rapide</span>
                        </div>
                    </div>
                </div>
            </div>

            {/* Colonne de gauche - Formulaire d'inscription */}
            <div className="w-full lg:w-1/2 flex items-center justify-center p-8">
                <div className="w-full max-w-md p-8">
                    <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">
                        {step === 1 ? 'Informations Personnelles' : 'Détails de Compte'}
                    </h2>

                    {step === 1 ? renderFirstStep() : renderSecondStep()}
                </div>
            </div>
        </div>
    );
};

export default Register;